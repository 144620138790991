/* Encabezado */
#executives-head {
  display: flex;
  justify-content: space-between;

  h1 {
    color: $dark-blue-1;
    font-family: 'Roboto';
    font-weight: normal;
    font-size: 15px;
  }

  .ant-btn-primary {
    background-color: $dark-blue-1;
    font-family: 'Roboto';
    text-transform: capitalize;
  }

  .content-buttons {
    display: flex;
    flex-direction: column;

    .last-button {
      margin-top: 10px;
    }
  }

  @media only screen and (min-width: 768px) {
    margin-top: 50px;

    h1 {
      font-size: 24px;
    }

    .ant-btn-primary {
      font-size: 16px;
      height: 45px;
    }

    .content-buttons {
      flex-direction: row;

      .last-button {
        margin-top: 0px;
        margin-left: 10px;
      }
    }
  }
}

/* Tabla */
#executives-main-table {
  .ant-table-thead {
    .ant-table-column-sorters,
    .ant-table-cell {
      background-color: $white;
      color: $dark-gray-2;
      font-family: 'Roboto';
      font-size: 8px;
      text-align: start !important;

      @media only screen and (min-width: 768px) {
        font-size: 0.75rem;
      }
    }
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: 768px) {
      justify-content: flex-start;
    }
  }

  .ant-table-row {
    background-color: $gray-1;

    td {
      border-bottom: 5px solid $white;
    }

    .ant-table-cell {
      padding-top: 0;
      padding-bottom: 0;
      height: 3rem;
      font-family: 'Roboto';
      font-size: 10px;

      @media only screen and (min-width: 768px) {
        font-size: 1rem;
      }

      p {
        margin: 0;
        text-align: start !important;
      }

      #ver {
        text-decoration: underline;
        color: $light-blue;
        cursor: pointer;
      }

      #eliminar {
        margin-top: 3px;

        @media only screen and (min-width: 768px) {
          margin-top: 8px;
          margin-left: 10px;
        }
      }

      svg {
        cursor: pointer;
      }
    }
  }
}

/* Modal Agregar Nuevo */
#executive-new-modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px 45px 0px 20px;
  font-family: 'Roboto';

  @media only screen and (min-width: 768px) {
    padding: 45px 57px 0px 45px;
  }

  h3 {
    font-weight: 500;
    color: $dark-blue-1;
    font-size: 23px;
    margin-bottom: 10px;
  }

  #modal-new-executive-form {
    @media only screen and (min-width: 768px) {
      padding-bottom: 20px;
    }

    .ant-form-item-label {
      color: $dark-blue-2;
      font-family: 'Roboto';
      font-size: 14px;
    }

    input {
      height: 35px;
      border-radius: 8px;
      background-color: $gray-1;
      border: 0;

      @media only screen and (min-width: 768px) {
        height: 42px;
      }
    }

    input:focus {
      background-color: #4292ff51; // Lightblue con opacity
      color: $light-blue;
      box-shadow: none;
      border: 1px solid $light-blue;
    }
  }
}

/* Modal Show Info Executive */
#executive-show-modal-body {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  padding: 20px 45px 0px 20px;

  @media only screen and (min-width: 768px) {
    padding: 45px 75px 0px 75px;
  }

  h3 {
    font-weight: 500;
    color: $dark-blue-1;
    font-size: 23px;
    margin-bottom: 27px;
  }

  #text {
    color: $dark-blue-2;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: justify;
  }

  #mails {
    color: $dark-blue-2;
    font-size: 16px;
  }

  #email {
    color: $dark-blue-2;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
  }
}

/* Footer de los Modales */
#executive-modal-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;

  button {
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 500;
    background-color: $light-blue;
    border-radius: 8px;
    height: auto;
    padding: 5px 65px;
  }
}

.button-holder > .ant-col > .ant-form-item-control-input {
  button {
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 500;
    background-color: $light-blue;
    border-radius: 8px;
    height: auto;
    padding: 5px 50px;
  }

  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
