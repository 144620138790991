.ant-input-number > .ant-input-number-handler-wrap,
.ant-form-item-control-input-content.ant-input-number-handler-wrap {
  display: none;
}

#title-space {
  margin-right: 20px;
  font-size: 0.8rem;
  font-family: 'Roboto';
  color: $dark-blue-2;
  font-weight: 500;
}

/* Sección compartida Application List y Taken Application List */
.applications-list {
  width: 95%;
  margin: 0 auto;

  @media only screen and (min-width: 992px) {
    width: auto;
    margin: auto;
  }

  #assign-title {
    font-weight: normal;
    color: $dark-blue-1;
    font-family: 'Roboto';
    font-size: 15px;

    @media only screen and (min-width: 992px) {
      margin-top: 2.5rem;
      font-size: 1.4rem;
    }
  }

  .applications-table {
    .ant-table-thead {
      .ant-table-column-sorters,
      .ant-table-cell {
        background-color: $white;
        color: $dark-gray-2;
        font-family: 'Roboto';
        font-size: 9px;

        @media only screen and (min-width: 768px) {
          font-size: 0.75rem;
        }
      }
    }

    .ant-table-row.open {
      background-color: $light-blue-transparent;
    }

    .ant-table-row.closed {
      background-color: $gray-1-light;
    }

    .ant-table-row {
      td {
        border-bottom: 10px solid $white;

        @media only screen and (min-width: 768px) {
          border-bottom: 5px solid $white;
        }
      }

      .ant-table-cell {
        padding-top: 0;
        padding-bottom: 0;
        height: 3rem;
        font-family: 'Roboto';
        font-size: 9px;

        @media only screen and (min-width: 768px) {
          text-align: start;
          font-size: 1rem;
        }

        p {
          margin: 0;
        }

        .ant-tag {
          height: 1.8rem;
          font-size: 8px;
          display: inline-flex;
          align-items: center;

          @media only screen and (min-width: 768px) {
            font-size: 1rem;
          }
        }

        .ant-tag.purple {
          color: #3d188e;
        }
        .ant-tag.red {
          color: #711a36;
        }
        .ant-tag.green {
          color: #0b5030;
        }

        #link {
          text-decoration: underline;
          color: $light-blue;
          cursor: pointer;
        }

        svg {
          cursor: pointer;
        }

        #link-ok {
          font-family: 'Roboto';
        }
      }
    }
  }
}

/* Contenedor de las Cotizaciones */
.application-show {
  font-family: 'Roboto';

  .application-header {
    display: flex;
    flex-direction: column;

    .labels {
      display: flex;
      justify-content: flex-end;
      margin-top: 18px;

      @media only screen and (min-width: 992px) {
        width: 100%;
        margin-bottom: -30px;
      }

      .ant-tag {
        font-family: 'Roboto';
        font-size: 0.98rem;
        padding: 3px 15px;
        color: $dark-blue-1;
        border-radius: 2px;
      }

      .bold {
        border-radius: 10px;
        font-weight: 500;
      }
    }

    .application-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      font-weight: 300;
      font-size: 0.8rem;

      .back a {
        color: $pink !important;
        font-weight: 500;

        @media only screen and (min-width: 992px) {
          font-size: 1rem;
        }
      }
    }

    .main-info {
      display: flex;
      flex-direction: column;
      font-size: 1rem;

      @media only screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
      }

      .title {
        display: flex;
        flex-direction: column;
        color: $dark-blue-1;

        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .name {
            max-width: 50%;
            font-weight: 500;
            font-size: 1.1rem;

            @media only screen and (min-width: 992px) {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 220px;
              margin-top: 10px;
              font-size: 1.35rem;
              padding-right: 10px;
              border-right: solid 1px $dark-blue-1;
            }
          }

          @media only screen and (min-width: 992px) {
            justify-content: start;
            .createdAt {
              height: 100%;
              padding: 0 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }

        .bottom {
          margin-top: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .userName {
            padding-right: 10px;
            display: flex;
            flex-direction: row;
          }

          .rut {
            font-size: 10px;
            margin-right: 10px;
          }

          .location {
            font-size: 10px;
          }

          @media only screen and (min-width: 992px) {
            justify-content: flex-start;

            .userName {
              font-size: 1rem;
            }

            .location,
            .rut {
              font-size: 0.7rem;
            }
          }
        }
      }

      .extra {
        display: flex;
        justify-content: center;
        align-items: center;

        .time-limit {
          padding: 8px 5px;
          background-color: $light-blue-transparent;
          border-radius: 5px;
          border: solid 1px $light-blue;
          color: $dark-blue-1;
          width: 100%;
          text-align: center;
          text-transform: capitalize;
          font-size: 12px;

          @media only screen and (min-width: 992px) {
            padding: 8px 20px;
            width: auto;
            font-size: 1rem;
          }

          .bold {
            font-weight: 500;
            padding-right: 10px;
          }

          @media only screen and (min-width: 992px) {
            .remaining {
              padding-left: 10px;
              border-left: solid 1px $dark-blue-1;
            }
          }
        }
      }
    }
  }

  #app-data {
    font-family: 'Roboto';

    @media only screen and (min-width: 992px) {
      margin-top: 35px;
    }

    .main-info {
      display: flex;
      flex-wrap: wrap;
      border-radius: 5px;
      background: linear-gradient($dark-blue-2, $dark-blue-1);
      color: $white;
      font-size: 0.95rem;
      justify-content: space-between;
      padding: 10px;

      @media only screen and (min-width: 1200px) {
        padding: 28px 0;
        flex-wrap: wrap;
        justify-content: space-evenly;

        div:not(:last-child) {
          border-right: solid 1px $white;
        }
      }

      @media only screen and (min-width: 992px) {
        div.no-border-right {
          border-right: none;
        }
        .main-info-container-2 {
          margin-top: 20px;
        }
      }

      .main-info-container-1,
      .main-info-container-2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 10px;

        @media only screen and (min-width: 992px) {
          text-align: center;
          margin-bottom: auto;
          margin-right: auto;

          .small-margin {
            margin-top: 25px;
          }
        }

        .title {
          font-weight: 500;
        }
      }

      .container-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .container-data1 {
          margin-top: 5px;
          border-right: none;
        }

        .container-data2 {
          margin-top: 5px;
        }
      }
    }

    .main-info.additional-property {
      color: $dark-blue-1;
      border: 2px solid $dark-blue-1;
      background: transparent;
      background-color: $gray-1;
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;

      div {
        flex: 25%;
        border-right: none;
      }

      .mini-content {
        flex: 100%;

        @media only screen and (min-width: 992px) {
          flex: 25%;
        }
      }

      .insurance-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        @media only screen and (min-width: 992px) {
          margin-bottom: 10px;
        }

        .insurance-item {
          display: flex;
          flex-direction: row;

          .no-selected-icon {
            width: 16px;
            height: 16px;
            margin-top: 3px;
            margin-bottom: 2px;
            margin-right: 2px;

            @media only screen and (min-width: 992px) {
              margin-right: 0;
            }
          }

          @media only screen and (min-width: 992px) {
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }

    .secondary-info {
      -webkit-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      -moz-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      display: flex;
      flex-direction: column;
      margin: 25px auto;
      background-color: $gray-1-light;
      border-radius: 5px;
      padding: 0 10px;

      @media only screen and (min-width: 992px) {
        padding: 20px 61px;
      }

      .holder-bottom {
        // Para borde inferior
        border-bottom: solid 3px transparent;
        border-image: linear-gradient(to left, $pink, $white);
        border-image-slice: 1;
      }

      .holder {
        border-top: solid 3px transparent;
        border-image: linear-gradient(to right, $pink, $white);
        border-image-slice: 1;

        .ant-descriptions-row:not(:last-child) {
          border-bottom: solid 1px $dark-gray-2-light;
        }

        .ant-descriptions-item {
          padding-top: 6px;
          padding-bottom: 6px;

          .ant-descriptions-item-content {
            font-size: 0.8rem;
            color: $dark-blue-1;
            font-weight: 500;

            @media only screen and (min-width: 768px) {
              font-size: 1.1rem;
            }

            div {
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              span {
                display: flex;
                align-items: center;
                padding-right: 15px;

                svg {
                  margin-right: 5px;
                }
              }

              span:last-child {
                width: 100%;
                margin-left: 3px;

                @media only screen and (min-width: 768px) {
                  width: auto;
                }
              }
            }
          }

          .ant-descriptions-item-label {
            font-size: 0.8rem;
            font-weight: 400;
            color: $dark-blue-2;
            min-width: 40%;

            @media only screen and (min-width: 992px) {
              font-size: 1.1rem;
              min-width: 30%;
            }
          }
        }
      }

      .part:not(:last-child) {
        border-bottom: solid 1px $dark-gray-2-light;
      }

      .part {
        display: flex;
        flex-direction: column;
        color: $dark-blue-1;
        padding-top: 10px;
        padding-bottom: 20px;

        @media only screen and (min-width: 992px) {
          padding-top: 22px;
          padding-bottom: 40px;
        }

        h2.part-title {
          color: $dark-blue-1;
          margin-bottom: 15px;
          font-weight: bold;
        }

        .container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          @media only screen and (min-width: 768px) {
            flex-wrap: nowrap;
          }

          div.last {
            color: $pink;
            font-weight: 600;
            font-stretch: condensed;
            min-width: 100%;

            @media only screen and (min-width: 768px) {
              padding-right: 0px;
              min-width: 200px;
              font-size: 1.35rem;
            }

            .title {
              color: $dark-gray-1;
              font-weight: normal;
              font-size: 0.95rem;
              font-stretch: normal;
            }
          }

          div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 0.9rem;
            margin-bottom: 10px;
            height: 60px;

            @media only screen and (min-width: 768px) {
              font-size: 1.1rem;
              margin-bottom: auto;
              height: auto;
            }

            .title {
              color: $dark-gray-1;
              font-size: 0.85rem;

              @media only screen and (min-width: 768px) {
                font-size: 0.95rem;
              }
            }

            .min-text {
              width: 80%;
            }
          }
        }
      }
    }
  }

  #changes-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .history,
    .new-change-request {
      width: 49%;
      min-height: 200px;
      background-color: $gray-1-light;
      padding: 30px;
      font-family: 'Roboto';
      h4 {
        font-weight: 500;
        color: $dark-blue-2;
      }
      max-height: 400px;
      overflow-y: scroll;
    }

    .new-change-request {
      overflow: hidden;
    }

    .new-change-request {
      textarea {
        margin-top: 10px;
        font-family: 'Roboto';
        min-height: 150px;
        border-radius: 4px;
        background-color: transparent;
      }
      input {
        font-family: 'Roboto';
        border-radius: 4px;
        background-color: transparent;
      }
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
      }

      button {
        align-self: center;
        margin: 0px 7px;
        color: $white;
        font-family: 'Roboto';
        background-color: $light-blue;
        border-radius: 4px;
        border: solid 1px $dark-blue-2;
        // padding: 10px;
        height: 40px;
        width: 190px;
      }
    }

    .history {
      table {
        width: 100%;
        color: $dark-blue-1;
        th,
        td {
          padding-left: 0.8rem;
        }
        .user {
          color: $pink;
        }
        tr {
          height: 2.2rem;
          border-bottom: solid 1px $dark-gray-2-light;
        }
      }
    }
  }

  #files-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
    .files {
      width: 100%;
      min-height: 200px;
      // background-color: $gray-1-light;
      padding: 30px;
      font-family: 'Roboto';

      #userFiles {
        display: flex;
        flex-direction: column;
        span {
          // border-bottom: solid 1px $dark-gray-1;
          padding-top: 2px;
        }
      }

      h4 {
        font-weight: 500;
        color: $dark-blue-2;
      }
      a {
        display: flex;
        align-items: center;
        svg {
          height: 1.1rem;
        }
      }
    }
  }

  #answer-container {
    font-family: 'Roboto';
    color: $dark-blue-2;
    display: flex;
    flex-direction: column;

    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
      background: unset; //Change the existing color to `unset`
    }

    .ant-table {
      padding: 15px 0;
      background-color: transparent;
      font-family: 'Roboto';
      color: $dark-blue-2;

      thead > tr > th {
        background-color: transparent;
        font-family: 'Roboto';
        font-size: 0.8rem;
        color: $dark-blue-2;
        border-bottom: solid 0px black;

        @media only screen and (min-width: 992px) {
          padding: 0px;
        }
      }

      tbody > .ant-table-row:hover {
        // transition: background 1.1s;
        background-color: transparent;
      }

      .ant-table-cell {
        height: 1rem;
        .ant-input-number,
        .ant-input-number-focused {
          // width: 80% !important;
          min-width: 80%;
          margin: auto;
          height: 35px;
        }
      }
      tbody > tr > td {
        margin: auto;
        height: 1rem;

        p {
          margin: auto;
        }

        div.ant-row.ant-form-item {
          margin: auto;
          height: 100%;
        }

        .ant-form-item-control-input,
        .ant-form-item-control-input-content,
        .ant-picker,
        .ant-select,
        .ant-select-selector {
          height: 100%;
          padding-bottom: 3px;
          margin-right: 3px;
        }

        .ant-select-selector > input {
          margin: auto;
        }

        @media only screen and (min-width: 992px) {
          padding: 0px;
        }

        margin: 0px;
        border-bottom: solid 0px black;

        // width: 10px;
        .ant-input-number,
        .ant-select-selector,
        .ant-picker {
          // width: 78%;
          background-color: transparent;
          border-radius: 4px;
          border: solid 1px $dark-blue-2;
          margin-right: 10px;
        }

        .ant-form-item-has-error {
          .ant-input-number,
          .ant-select-selector,
          .ant-picker {
            background-color: transparent;
            border-radius: 4px;
            border: solid 1px $pink;
            margin-right: 10px;
          }
        }
      }
    }

    .main-answer {
      background-color: $gray-1-light;
      margin-bottom: 20px;
      padding: 10px;

      // border: 1px solid red;

      // // width: 100%;
      // padding: 15px 15px 15px 0;

      .ant-input-number {
        border-radius: 5px;
        border-color: $dark-blue-2;
        height: 35px;
        width: 80%;
        background-color: $gray-1-light;

        // margin-right: 45px;
      }

      // // .ant-form-item-explain {
      // //   width: 60%;
      // // }
      // // padding: 15px 15px;

      @media only screen and (min-width: 992px) {
        padding: 25px 40px;
      }
    }

    .rejected-answer {
      background-color: $pink-light;
      border: solid 1px $pink;
      margin: 30px 0;
      padding: 14px 37px;
      font-size: 1rem;
      font-family: 'Roboto';
      color: $pink-dark;
    }

    .secondary-answer {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .answer-file {
        width: 640px;
        padding: 25px 82px;
        .ant-upload-text {
          font-weight: 400;
          color: $dark-gray-2;
          font-size: 0.9rem;
          text-align: center;
          width: 60%;
          margin: auto;
        }

        .ant-upload {
          padding: 3;
        }
        .ant-upload-drag {
          background-color: $gray-1-light;
          border-radius: 4px;
          border: dotted 1px $dark-gray-2-light;
          padding: 0 5px;
        }

        p {
          padding-top: 10px;
          font-weight: 500;
          color: $dark-blue-2;
        }

        textarea {
          background-color: transparent;
          border-radius: 4px;
          border: solid 1px $dark-blue-2;
          height: 220px;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-evenly;
      margin-top: 30px;

      @media only screen and (min-width: 992px) {
        justify-content: center;
      }

      button {
        color: $dark-blue-2;
        font-family: 'Roboto';
        background-color: transparent;
        border-radius: 4px;
        border: solid 1px $dark-blue-2;
        width: 105px;

        @media only screen and (min-width: 992px) {
          margin: 0px 7px;
          height: 40px;
          width: 190px;
        }
      }
    }

    button.send {
      background-color: $dark-blue-1;
      color: $white;
    }

    .reject > button,
    button.reject {
      color: $pink-dark;
      border: solid 1px $pink;
    }

    .terms {
      text-align: center;
    }
  }
}
