.summary {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
}

.view-executive-file {
  color: $light-blue;
  cursor: pointer;
}

.small-size {
  font-size: 17px !important;

  @media only screen and (min-width: 992px) {
    font-size: 23px !important;
  }
}
