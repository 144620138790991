/* Sección Home */
.homeContent {
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 992px) {
    margin-top: 7rem;
  }

  .change-password {
    text-align: center;
    margin-top: 3rem;

    @media only screen and (min-width: 992px) {
      text-align: start;
    }

    button {
      border-radius: 4px;
      height: 40px;
      font-size: 1rem;
    }
  }

  .info {
    font-family: 'Roboto';
    color: $dark-blue-1;
    width: 95%;
    padding-left: 10px;

    @media only screen and (min-width: 992px) {
      padding-top: 2rem;
      padding-left: 5rem;
      width: 60%;
    }

    .mainInfo {
      border-bottom: 1px solid $dark-blue-1;

      h3 {
        margin-bottom: 0px;
        font-weight: bold;
        font-size: 1.1rem;

        @media only screen and (min-width: 992px) {
          font-size: 1.4rem;
        }
      }

      p {
        font-size: 0.9rem;
        margin-bottom: 10px;

        @media only screen and (min-width: 992px) {
          font-size: 1.1rem;
        }
      }
    }

    .secondaryInfo {
      border-bottom: 1px solid $dark-blue-1;
      margin-top: 15px;

      @media only screen and (min-width: 992px) {
        margin-top: 35px;
        padding-bottom: 20px;
      }

      p {
        font-size: 0.9rem;
        margin-bottom: 10px;

        @media only screen and (min-width: 992px) {
          font-size: 1.1rem;
        }

        span {
          font-weight: bold;
        }
      }
    }
  }
}
