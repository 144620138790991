/* Sección no compartida AssingList */
.assign-list {
  #assign-title {
    font-weight: normal;
    text-transform: capitalize;
    color: $dark-blue-1;
    font-family: 'Roboto';
    font-size: 15px;

    @media only screen and (min-width: 768px) {
      font-size: 1.5rem;
      margin-top: 3.125rem;
    }
  }

  #assign-main-table {
    .ant-table-thead {
      .ant-table-column-sorters,
      .ant-table-cell {
        background-color: $white;
        padding: auto;
        color: $dark-gray-2;
        font-family: 'Roboto';
        font-size: 8px;

        @media only screen and (min-width: 768px) {
          font-size: 0.75rem;
        }
      }
    }

    .mini-tag {
      font-size: 9px;
      padding: 0 2px;
      margin-right: 0;

      @media only screen and (min-width: 768px) {
        padding: 0 7px;
        font-size: 12px;
        margin-right: 8px;
      }
    }

    .min-button {
      font-size: 9px;

      @media only screen and (min-width: 768px) {
        font-size: 14px;
      }
    }

    .ant-table-row {
      background-color: $gray-1;

      td {
        border-bottom: 10px solid $white;

        @media only screen and (min-width: 768px) {
          border-bottom: 5px solid $white;
        }
      }

      .ant-table-cell {
        font-family: 'Roboto';
        font-size: 9px;
        text-align: start;

        @media only screen and (min-width: 768px) {
          padding: 0px 16px;
          font-size: 1rem;
        }

        p {
          margin: 0;
        }

        #link {
          text-decoration: underline;
          color: $light-blue;
          cursor: pointer;
        }

        svg {
          cursor: pointer;
        }
        #link-disabled > svg {
          cursor: not-allowed;
        }
      }
    }
  }
}

/* Footer de los Modales */
#assign-modal-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;

  button {
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 500;
    background-color: $light-blue;
    border-radius: 8px;
    height: auto;
    padding: 5px 35px;

    @media only screen and (min-width: 768px) {
      padding: 5px 65px;
    }
  }
}

#unassign-modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px 35px 0px 20px;
  font-family: 'Roboto';

  @media only screen and (min-width: 768px) {
    padding: 45px 57px 0px 75px;
  }

  h3 {
    margin-bottom: 27px;
    font-size: 23px;
    font-weight: 500;
    color: $dark-blue-1;
  }

  p {
    font-size: 16px;
    color: $dark-blue-2;
  }
}

#assign-modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px 35px 0px 20px;

  font-family: 'Roboto';

  @media only screen and (min-width: 768px) {
    padding: 45px 57px 0px 75px;
  }

  h3 {
    margin-bottom: 27px;
    font-size: 23px;
    font-weight: 500;
    color: $dark-blue-1;
  }

  p {
    font-size: 16px;
    color: $dark-blue-2;
  }

  #select-executive {
    .ant-select {
      width: 100%;
      height: 42px;
      margin-bottom: 40px;

      .ant-select-selector {
        box-shadow: none;

        height: 100%;
        background-color: $gray-1;
        border: 0px;
        border-radius: 8px;
        font-family: 'Roboto';
        font-size: 16px;
        span {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }

    .ant-select-selector:hover {
      background-color: #4292ff51; // Lightblue con opacity
      color: $light-blue;
      box-shadow: none;
      border: 1px solid $light-blue;
    }

    .ant-select-item-option {
      height: 42px;
    }
  }
}
