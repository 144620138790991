/* Sección Background */
.sign-in-div {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: linear-gradient(rgba(1, 0, 58, 0.6), rgba(1, 0, 58, 0.6)),
    url(../icons/session/rectangle-17.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  min-height: 100vh;

  @media only screen and (min-width: 992px) {
    background-image: none;
  }

  .mini-phone-background {
    margin-top: 130px;
    background-color: $white-smoke;
    padding: 20px 0px;
    border-radius: 5px;

    @media only screen and (min-width: 992px) {
      margin-top: 0;
      background-color: transparent;
      padding: 0;
      border-radius: 0;
    }

    .flit-logo {
      @media only screen and (min-width: 992px) {
        margin-top: 130px;
      }

      svg {
        height: 50px;

        @media only screen and (min-width: 992px) {
          height: auto;
        }
      }
    }

    .sign-in-form {
      width: 320px;
      padding: 0px 25px;

      @media only screen and (min-width: 992px) {
        margin-top: 80px;
        padding: 0;
      }

      .ant-tabs-nav {
        width: 100%;
      }

      .ant-tabs-tab {
        width: 50%;
        text-align: center;
      }

      .ant-input {
        height: 40px;
        border-radius: 8px;
        border: 1.15px solid $dark-blue-1;
        background-color: $white;
      }

      .ant-row:nth-child(2) {
        margin: 0;
      }

      .form-actions {
        .link {
          text-align: end;
          a {
            color: $dark-blue-1;
          }
        }

        .button {
          background-color: $dark-blue-1;
          border-radius: 4px;
          border: 0px;
        }
      }
    }
  }
}

/* Sección Password */
.password-forget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';

  background-image: linear-gradient(rgba(1, 0, 58, 0.6), rgba(1, 0, 58, 0.6)),
    url(../icons/session/rectangle-17.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  min-height: 100vh;

  @media only screen and (min-width: 992px) {
    background-image: none;
  }

  .mini-phone-background {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: $white-smoke;
    padding: 20px 10px;
    border-radius: 5px;

    @media only screen and (min-width: 992px) {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
    }

    h1 {
      color: $dark-blue-1;
      font-weight: 500;
      font-size: 1.5rem;
      padding-bottom: 10px;
    }

    form {
      width: 25%;
      min-width: 250px;
    }

    form > button {
      width: 100%;
      margin-bottom: 20px;
      background-color: $dark-blue-1;
      border: solid 0px black;
      color: $white;
    }
  }
}

#change-new-modal-body {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  padding: 20px 45px 0px 20px;

  @media only screen and (min-width: 992px) {
    padding: 45px 57px 0px 75px;
  }

  h3 {
    font-size: 23px;
    font-weight: 500;
    color: $dark-blue-1;
    margin-bottom: 27px;
  }

  #modal-new-change-form {
    padding-bottom: 20px;

    .ant-form-item {
      margin: 0px;
    }

    .ant-form-item-label {
      padding: 0;
      color: $dark-blue-2;
      font-family: 'Roboto';
      font-size: 14px;
    }

    input {
      margin: 10px 0px;
      height: 35px;
      border-radius: 8px;
      background-color: $gray-1;
      border: 0px;

      @media only screen and (min-width: 992px) {
        height: 42px;
      }
    }

    input:focus {
      background-color: #4292ff51; // Lightblue con opacity
      color: $light-blue;
      box-shadow: none;
      border: 1px solid $light-blue;
    }
  }
}

/* Footer Modal Password */
#change-modal-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;

  button {
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 500;
    background-color: $light-blue;
    border-radius: 8px;
    height: auto;
    text-transform: capitalize;

    @media only screen and (min-width: 992px) {
      font-size: 18px;
      padding: 5px 65px;
    }
  }
}
