.ant-input-number > .ant-input-number-handler-wrap,
.ant-form-item-control-input-content.ant-input-number-handler-wrap {
  display: none;
}

#title-space {
  margin-right: 20px;
}

/* Sección Application List */
.applications-list {
  #assign-title {
    //   margin-top: 2.5rem;
    //   font-size: 1.4rem;
    //   font-weight: normal;

    //   color: $dark-blue-1;
    // font-family: 'Roboto';
  }

  .applications-table {
    //   // padding-right: 10px;

    //   @media only screen and (min-width: 768px) {
    //     padding-right: 0;
    //   }

    .ant-table-thead {
      // .ant-table-column-sorters,
      // .ant-table-cell {
      //   font-size: 8px;
      //   // text-align: center;

      //   @media only screen and (min-width: 768px) {
      //     // text-align: start;
      //     font-size: 0.75rem;
      //   }
      //   // color: $dark-gray-2;
      //   // font-family: 'Roboto';
      //   // font-size: 0.75rem;
      // }
    }

    // .ant-table-row.open {
    //   background-color: $light-blue-transparent;
    // }
    // .ant-table-row.closed {
    //   background-color: $gray-1-light;
    // }

    .ant-table-row {
      td {
        // border-bottom: 10px solid $white;

        // @media only screen and (min-width: 768px) {
        //   border-bottom: 5px solid $white;
        // }
      }
      .ant-table-cell {
        // text-align: center;
        // padding-top: 0;
        // padding-bottom: 0;
        // height: 3rem;
        // font-size: 10px;
        // @media only screen and (min-width: 768px) {
        //   text-align: start;
        //   font-size: 1rem;
        // }

        // font-family: 'Roboto';

        //       p {
        //         margin: 0;
        //       }

        .ant-tag {
          // font-size: 10px;
          // height: 1.8rem;
          // display: inline-flex;
          // align-items: center;

          // @media only screen and (min-width: 768px) {
          //   font-size: 1rem;
          // }
        }
        //       #link {
        //         text-decoration: underline;
        //         color: $light-blue;
        //         cursor: pointer;
        //       }

        //       svg {
        //         cursor: pointer;
        //       }
        //       #link-ok {
        //         font-family: 'Roboto';
        //       }
      }
    }
  }
}

/* Sección de Solicitudes Adjudicadas */
.application-show {
  #taken-answer {
    .first {
      background: linear-gradient($dark-blue-2, $dark-blue-1);
      border-radius: 5px;
      padding: 10px;
      color: $white;

      @media only screen and (min-width: 768px) {
        padding: 28px 60px;
      }

      h2 {
        font-family: 'Roboto slab';
        color: $white;
        padding-bottom: 5px;
        border-bottom: solid 1px $white-transparent;
      }

      .data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media only screen and (min-width: 768px) {
          flex-wrap: nowrap;
        }

        div {
          display: flex;
          flex-direction: column;
          margin-right: 10%;
          margin-bottom: 10px;

          @media only screen and (min-width: 768px) {
            margin-right: 0;
            margin-bottom: auto;
          }

          span.title {
            font-weight: 500;
          }
        }
      }
    }

    .second {
      z-index: 2;
      -webkit-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      -moz-box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.18);
      background-color: $gray-1-light;
      margin-top: 20px;
      padding: 10px;
      border-radius: 5px;
      color: $dark-blue-1;

      @media only screen and (min-width: 768px) {
        padding: 28px 60px;
      }

      h2 {
        font-family: 'Roboto slab';
        color: $dark-blue-1;
        padding-bottom: 5px;
        border-bottom: solid 1px $dark-gray-2-light;
      }

      .data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media only screen and (min-width: 768px) {
          flex-wrap: nowrap;
        }

        div {
          display: flex;
          flex-direction: column;
          margin-right: 20px;
          margin-bottom: 10px;

          @media only screen and (min-width: 768px) {
            margin-right: 0;
            margin-bottom: auto;
          }

          span.title {
            font-weight: 500;
          }
        }
      }
    }

    .files {
      cursor: pointer;
      background-color: #e8168720;
      border: solid 1px $pink;
      border-radius: 5px;
      margin: auto;
      margin-top: 30px;
      display: flex;
      padding: 18px 0;
      justify-content: center;

      @media only screen and (min-width: 768px) {
        padding: 24px 121px;
        width: 60%;
      }

      span {
        color: $pink;
        svg {
          fill: $pink;
        }
        display: flex;
        justify-content: center;
        margin: auto;
      }
    }

    .buttons {
      // margin: auto;
      margin-top: 30px;
      margin-bottom: 10px;
      padding-top: 30px;
      border-top: solid 1px $dark-gray-2-light;
      // width: 80%;
      display: flex;
      justify-content: center;

      button {
        margin: 0px 7px;
        color: $dark-blue-2;
        font-family: 'Roboto';
        background-color: transparent;
        border-radius: 4px;
        border: solid 1px $dark-blue-2;
        height: 40px;
        width: 190px;
      }
    }

    button.send {
      background-color: $dark-blue-1;
      color: $white;
    }

    .reject > button,
    button.reject {
      color: $pink-dark;
      border: solid 1px $pink;
      // background-color: $pink-light;
    }
  }

  #chat-container {
    min-height: 600px;
    max-height: 600px;
    margin: 5px;
    padding: 20px;
    -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.21);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.21);

    display: flex;
    flex-direction: column;

    h4 {
      font-weight: 400;
      color: #4d4f5c;
      padding-bottom: 10px;
      width: 100%;
      text-align: center;
      border-bottom: solid 1px $dark-gray-2-light;
    }

    .chat {
      flex-grow: 1;
      overflow-y: scroll;
      padding: 0 20px;

      .bank,
      .user {
        width: 100%;
        .message {
          width: 35%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: 20px;

          @media only screen and (min-width: 768px) {
            width: 25%;
          }

          p {
            width: 100%;
            max-width: 300px;
            min-width: 120px;
            margin: 0;
            padding: 10px 15px;
          }

          span {
            font-size: 0.7rem;
          }
        }
      }

      .bank {
        display: flex;
        justify-content: flex-end;
        .message {
          p {
            background-color: $light-blue;
            border-radius: 28px 28px 0px 28px;
            -moz-border-radius: 28px 28px 0px 28px;
            -webkit-border-radius: 28px 28px 0px 28px;
            color: white;
          }
        }
      }

      .user {
        display: flex;
        justify-content: flex-start;
        .message {
          p {
            background-color: $chat-user;
            border-radius: 28px 28px 28px 0px;
            -moz-border-radius: 28px 28px 28px 0px;
            -webkit-border-radius: 28px 28px 28px 0px;
            color: #4d4f5c;
          }
        }
      }
    }

    .send {
      border-top: solid 1px $dark-gray-2-light;
      padding-top: 15px;
      .ant-btn-primary,
      .ant-input-group-addon,
      .ant-btn,
      .ant-input-search-button {
        background-color: transparent;
        border: none;
        -webkit-box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
        -moz-box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
        box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
      }
      .ant-input {
        border: none;
        -webkit-box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
        -moz-box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
        box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
      }
    }
  }

  #requirements-container {
    margin: 5px;

    @media only screen and (min-width: 768px) {
      padding: 20px;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;

      .ant-btn-primary {
        color: $white;
        font-size: 12px;
        height: 35px;
        background-color: $dark-blue-1;
        font-family: 'Roboto';

        @media only screen and (min-width: 768px) {
          height: 45px;
          font-size: 1rem;
        }
      }
    }

    .requirements {
      @media only screen and (min-width: 768px) {
        margin-top: -15px;
      }

      .new,
      .old {
        p.head {
          color: $dark-gray-1;
        }

        .requirement.old-req {
          background-color: $gray-1;
        }

        .requirement {
          border-radius: 10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          -webkit-box-shadow: 0px 3px 6px 4px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 0px 3px 6px 4px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 3px 6px 4px rgba(0, 0, 0, 0.16);
          margin-bottom: 25px;
          padding: 10px;

          @media only screen and (min-width: 768px) {
            height: auto;
            padding: 20px 45px;
          }

          .title {
            h3 {
              margin: 0;
              padding: 0;
              color: $dark-blue-2;
              font-weight: 700;
              font-size: 12px;

              @media only screen and (min-width: 768px) {
                font-size: 1.2rem;
              }
            }

            h4 {
              margin: 0;
              padding: 0;
              color: $pink;
              font-weight: 500;
              font-size: 11px;

              @media only screen and (min-width: 768px) {
                font-size: 1rem;
              }
            }
          }

          .info {
            p {
              margin-bottom: 0;
            }

            a {
              margin-top: 5px;
              display: flex;
              font-size: 12px;

              @media only screen and (min-width: 768px) {
                font-size: 14px;
              }
            }
          }

          .answer {
            display: flex;
            flex-direction: column;
            justify-content: center;

            p,
            a {
              margin: 0;
              color: $light-blue;
              font-weight: 700;
              font-size: 10px;

              @media only screen and (min-width: 768px) {
                font-size: 0.8rem;
              }
            }

            a {
              font-weight: 500;
              display: flex;
            }
          }
        }
      }
    }
  }
}

/* Footer de los modales de las Pestañas Oferta y Requerimientos */
#requirement-modal-footer,
#report-modal-footer,
#reject-modal-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  button {
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 500;
    background-color: $light-blue;
    border-radius: 8px;
    height: 40px;
    padding: 5px 65px 10px 65px;
  }
}

/* Body de los modales de las Pestañas Oferta y Requerimientos */
#requirement-new-modal-body,
#report-new-modal-body,
#reject-new-modal-body {
  display: flex;
  flex-direction: column;
  padding-top: 45px;
  padding-left: 25px;
  padding-right: 25px;

  @media only screen and (min-width: 768px) {
    padding-left: 75px;
    padding-right: 57px;
  }

  h3 {
    font-size: 23px;
    font-weight: 500;
    font-family: 'Roboto';
    color: $dark-blue-1;
    margin-bottom: 27px;
  }

  #modal-new-requirement-form,
  #modal-new-report-form,
  #modal-new-reject-form {
    padding-bottom: 23px;

    .ant-form-item {
      margin: 0px;
    }
    .ant-form-item-label {
      padding: 0;
      color: $dark-blue-2;
      font-family: 'Roboto';
      font-size: 14px;
    }

    .ant-upload {
      cursor: pointer;
      color: $light-blue;
    }

    input,
    textarea {
      margin: 0px;
      height: 42px;
      border-radius: 8px;
      background-color: $gray-1;
      border: 0px;
    }

    textarea {
      min-height: 150px;
    }

    input:focus,
    textarea:focus {
      background-color: #4292ff51; // Lightblue con opacity
      color: $light-blue;
      box-shadow: none;
      border: 1px solid $light-blue;
    }
  }
}
