/* Importar Estilos de los componentes de ANTD */
@import '~antd/dist/antd.css';

/* Importar Colores y Fuentes*/
@import 'colors';
@import 'fonts';

/* Importar Estilos de los Componentes */
@import './../components/Layout/layout.scss';
@import './../components/Session/session.scss';
@import './../components/AssignApplications/assignApplications.scss';
@import './../components/Applications/applications.scss';
@import './../components/TakenApplications/takenApplications.scss';
@import './../components/Executives/executives.scss';
@import './../components/Home/index.scss';
@import './../components/Requirements/commonreq.scss';

/* Definir Tamaño de Fuentes */
.font-152 {
  font-size: 12vw;
}

.font-60 {
  font-size: 4.5vw;
}

.font-50 {
  font-size: 4vw;
}

.font-28 {
  font-size: 2vw;
}

.font-21 {
  font-size: 1.7vw;
}

.font-18 {
  font-size: 1.4vw;
}

.font-14 {
  font-size: 1.1vw;
}

.font-12 {
  font-size: 0.9vw !important;
}

.font-9 {
  font-size: 0.7vw;
}

/* Definir Estilos para los Modales */
.ant-modal-content {
  width: 350px;
  border-radius: 8px;

  @media only screen and (min-width: 992px) {
    width: 600px;
  }
}
.ant-modal-body {
  padding: 0px;
}

.ant-modal-footer {
  border-top: 0px solid $very-light-gray !important;
  padding: 0px;
}

// Estilo para el ícono de advertencia de mensajes
.info-icon-style {
  svg {
    color: $pink;
    height: 10px;
    width: 10px;

    @media only screen and (min-width: 992px) {
      height: 15px;
      width: 15px;
    }
  }
}

.info-icon-menu-style {
  svg {
    color: $white;
    height: 18px !important;
    width: 18px !important;
    margin-left: 10px;

    @media only screen and (min-width: 992px) {
      height: 20px !important;
      width: 20px !important;
      margin-left: 0%;
    }
  }
}
