/* Fuente Roboto */
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Thin.ttf) format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Light.ttf) format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Black.ttf) format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Italic.ttf) format('truetype');
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-LightItalic.ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(./fonts/Roboto/Roboto-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-BlackItalic.ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* Fuente Roboto Condensed */
@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed'),
    url(./fonts/Roboto_Condensed/RobotoCondensed-Light.ttf) format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed'),
    url(./fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format('truetype');
  font-weight: 400;
}

/* Fuente Roboto Slab */
@font-face {
  font-family: 'Roboto Slab';
  src: local('Roboto Slab'),
    url(./fonts/Roboto_Slab/RobotoSlab-Thin.ttf) format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto Slab';
  src: local('Roboto Slab'),
    url(./fonts/Roboto_Slab/RobotoSlab-Light.ttf) format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Roboto Slab';
  src: local('Roboto Slab'),
    url(./fonts/Roboto_Slab/RobotoSlab-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto Slab';
  src: local('Roboto Slab'),
    url(./fonts/Roboto_Slab/RobotoSlab-Bold.ttf) format('truetype');
  font-weight: 700;
}
