.ant-layout-content.main-content {
  background-color: $white;
}

/* Clase del Componente Content */
.ant-layout-content.content {
  background-color: $white;
  padding: 10px 10px 0 10px;

  @media only screen and (min-width: 992px) {
    padding: 13px 90px 0 90px;
  }
}

.ant-layout-header.landing-header {
  background-color: $transparent;
  border-color: black;
  border-style: solid;
}

@media only screen and (max-width: 600px) {
  .ant-layout-sider.session-sider {
    display: none;
  }
}

.ant-layout-sider.session-sider {
  background-image: url('./../icons/session/rectangle-17.png');
  background-repeat: no-repeat;
  background-size: cover;

  .sider-session-image:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: $dark-blue-2;
    opacity: 0.6;
  }
}

/* Sección del SideBar Menú */
#side-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Roboto';
  background-color: $dark-blue-2;
  min-width: 100% !important;

  @media only screen and (min-width: 992px) {
    max-width: 368px !important;
    min-width: 330px !important;
    width: 20% !important;
  }

  .logo {
    text-align: center;
    margin: 0px 0 15px 0;

    @media only screen and (min-width: 992px) {
      margin: 55px 0;
    }
  }

  .side-content-items {
    display: flex;
    justify-content: space-between;

    @media only screen and (min-width: 992px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .side-item {
    font-size: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    white-space: pre-wrap;
    line-height: 20px;
    font-family: 'Roboto';
    color: $gray-2;
    background-color: $dark-blue-2;
    margin: 0px;

    @media only screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      height: 50px;
      font-size: 1rem;
      padding: 1.5rem 0;
    }

    a {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      @media only screen and (min-width: 992px) {
        height: 100%;
        flex-direction: row;
      }
    }

    svg {
      height: 24px;
      vertical-align: middle;

      @media only screen and (min-width: 992px) {
        margin-left: 1.6rem;
        margin-right: 10px;
      }
    }

    p {
      display: inline-block;
      margin: 0;
    }
  }
  .side-item.ant-menu-item-selected {
    font-weight: 500;
    color: $dark-blue-1;

    @media only screen and (min-width: 992px) {
      background-color: $white-transparent;
      border-radius: 4px;
    }
  }

  @media only screen and (min-width: 992px) {
    .sign-out-item {
      position: absolute;
      bottom: 0px;
    }
  }
}

/* Sección Header */
#header {
  display: flex;
  align-items: center;
  background-color: $gray-1;
  height: 5rem;

  @media only screen and (min-width: 768px) {
    justify-content: space-between;
    padding: 0 90px;
  }

  .site-name-2 {
    font-family: 'Roboto Slab';
    font-size: 14px;
    font-weight: bold;
    color: $dark-blue-1;

    @media only screen and (min-width: 768px) {
      font-size: 28px;
    }

    p {
      margin: 0;
    }
  }

  .account-info-2 {
    color: $dark-gray-2;
    font-family: 'Roboto';
    font-size: 21px;
    display: flex;

    p {
      margin: 0;
      line-height: 55px;
      border-left: 1px solid $dark-blue-1;
      padding-left: 50px;
    }
  }
}
