/* Tonos */
$blue: #173f8a;
$light-blue: #4292ff;
$light-blue-transparent: #4291ff11;
$dark-blue-1: #050358;
$dark-blue-2: #01003a;

$white: #ffffff;
$white-transparent: #ffffff59;
$white-smoke: #f5f5f5;

$gray-1: #f5f8fa;
$gray-1-light: #f5f8fa70;
$dark-gray-1: #abb8c3;
$gray-2: #dde8f1;
$dark-gray-2: #52575a;
$dark-gray-2-light: #52575a22;
$chat-user: #edf0f5;
$very-light-gray: #f0f0f0;

$pink: #e81687;
$pink-light: #e8168638;
$pink-dark: #ca217b;

$transparent: rgba(0, 0, 0, 0);
